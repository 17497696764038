<template>
    <div>
        <div class="d-flex flex-column align-items-center bg-green rounded p-5 shadow-sm">
            <b-iconstack class="text-white" font-scale="6">
                <b-icon stacked icon="lightning-charge-fill" scale="0.5"></b-icon>
                <b-icon stacked icon="circle"></b-icon>
            </b-iconstack>
            <div class="text-white fs-4 mb-2">Nova Resposta Rápida</div>
            <b-button variant="light" class="text-green px-4" v-b-modal.modalAnswerSave>Criar</b-button>
        </div>
        <div class="bg-white rounded shadow-sm">
            <div class="text-purple d-flex justify-content-between align-items-center p-3 rounded-top mt-3 border-bottom border-grey">
                <div class="fs-5">Respostas Rápidas</div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Respostas</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ answers?.length || 0 }}</div>
                    <b-button class="text-white" variant="green" v-b-modal.modalFilter>Filtrar</b-button>
                </div>
            </div>
            <div v-if="answers?.length">
                <div class="text-purple px-3 py-2 fw-bold">
                    <b-row class="m-0">
                        <b-col class="p-0">
                            <div>Título</div> 
                        </b-col>
                        <b-col class="p-0">
                            <div>Tags</div>
                        </b-col>
                        <b-col class="p-0">
                            <div>Departamentos</div>
                        </b-col>
                    </b-row>
                </div>
                <div class="text-purple">
                    <b-row class="border-top px-3 py-2 m-0" v-for="item in answers" :key="item.id">
                        <b-col class="p-0 d-flex align-items-center">
                            <div>
                                {{ item.name }}
                            </div> 
                        </b-col>
                        <b-col class="p-0 d-flex align-self-center flex-column">
                            <div v-if="item.shortcuts?.length">
                                <span v-for="(shortcut,i) in item.shortcuts" :key="shortcut?.id">
                                    <span>
                                        {{ shortcut }}<span v-if="i+1<item.shortcuts.length">,</span> 
                                    </span>
                                </span>
                            </div>
                        </b-col>
                        <b-col class="p-0 d-flex justify-content-between align-items-center">
                            <div>
                                <div v-if="item.departments?.length">
                                    <span v-for="(dep,i) in item.departments" :key="dep?._id">
                                        <span>
                                            {{ depName(dep) }}<span v-if="i+1<item.departments.length">,</span> 
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="answersoptionsbtns">
                                <b-button class="answersoptionsbtn" v-b-modal.modalAnswerSave v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" style="background-color:hsl(94deg 44% 65%)" @click="selectAnswer(item)"><b-icon icon="pencil-square"></b-icon></b-button>
                                <b-button class="answersoptionsbtn" v-b-modal.modalAnswerDelete v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" style="background-color:hsl(360deg 83% 69%)" @click="selectAnswer(item)"><b-icon icon="trash"></b-icon></b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="bg-white rounded-bottom text-center p-3" v-else>
                <b-spinner v-if="loadingAnswers"></b-spinner>
                <div class="text-secondary" v-else>Nenhuma Resposta Rápida Encontrada!</div>
            </div>
        </div>
        <b-modal id="modalAnswerSave" ref="modalAnswerSave" header-class="py-1" :title="objAux._id ? 'Editar Resposta Rápida' : 'Criar Resposta Rápida'" size="xl" hide-footer v-if="objAux">
            <b-form class="text-purple" @submit.prevent="saveAnswer">
                <b-row class="m-0">
                    <b-col class="p-0 me-3">
                        <b-form-group label="Nome:" label-class="small-text">
                            <b-form-input v-model="objAux.name" class="w-100 border-grey py-1" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col class="p-0">
                        <b-form-group label="Departamentos:" label-class="small-text">
                            <div class="position-relative">
                                <div class="border rounded w-100 text-secondary py-1 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseDepSelect>
                                    <span v-if="objAux.departments && objAux.departments.length">
                                        <span v-for="(dep,i) in objAux.departments" :key="dep.id">
                                            <span>
                                                {{ depName(dep) }}<span v-if="i+1<objAux.departments.length">,</span> 
                                            </span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        &nbsp;
                                    </span>
                                    <span>
                                        <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                    </span>
                                </div>
                                <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class="mt-2 position-absolute bg-white border w-100 rounded" style="z-index: 1">
                                    <b-form-checkbox-group
                                        v-model="objAux.departments"
                                        style="z-index: 1"
                                    >
                                        <div v-for="item in departments" :key="item.id">
                                            <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item._id"> 
                                                <div class="ms-1" role="button">{{ item.name }}</div>
                                            </b-form-checkbox>
                                        </div>
                                    </b-form-checkbox-group>
                                    <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect></div>
                                </b-collapse>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="m-0 mt-3">
                    <b-col class="p-0">
                        <b-form-group label="Tags:">
                            <b-form-tags 
                                class="inputKeywords border-grey mt-3" 
                                add-button-text="Adicionar"
                                add-button-variant="primary"
                                duplicate-tag-text="Tag duplicada"
                                limit-tags-text="Limite atingido: 5"
                                :limit="5"
                                tag-remove-label="Remover Tag"
                                tag-removed-label="Tag removida"
                                invalid-tag-text="Tag Inválida"
                                :tag-validator="tagValidator"
                                placeholder="Adicionar Tag"
                                v-model="objAux.shortcuts"
                            ></b-form-tags>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="m-0 mt-3">
                    <b-col class="p-0">
                        <b-form-group class="position-relative" label="Conteúdo:">
                            <b-form-textarea
                                v-model="objAux.content"
                                class="px-4 border-grey"
                                id="textarea"
                                rows="4"
                                no-resize
                                required
                            ></b-form-textarea>
                            <b-button class="toggle-emoji" @click="toggleDialogEmoji"><b-icon class="toggle-emoji-icon" icon="emoji-smile"></b-icon></b-button>
                            <VEmojiPicker @select="selectEmoji" lang="pt-BR" v-show="showDialog" :i18n="i18n" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="mt-4">
                    <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                    <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalAnswerSave')">Cancelar</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="modalFilter" ref="modalFilter" hide-footer title="Filtrar Atendimentos" header-class="py-1">
            <form @submit.prevent="getAnswers" class="w-100">
                <b-form-group label="Selecione o Filtro:" label-class="text-purple fw-semibold">
                    <b-form-select class="w-100 rounded p-2 border-grey" v-model="filters.type" required>
                        <b-form-select-option :value="null">Todos</b-form-select-option>
                        <b-form-select-option value="keyword">Palavra-Chave</b-form-select-option>
                        <b-form-select-option value="department">Departamento</b-form-select-option>
                    </b-form-select>
                    <b-form-input class="mt-3 border-grey" placeholder="Buscar por Título" v-model="filters.keyword" v-if="filters.type == 'keyword'"></b-form-input>
                    <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="filters.department" v-else-if="filters.type == 'department'">
                        <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </form>
            <div class="mt-3">
                <b-button class="text-white" variant="green" @click="getAnswers(); $bvModal.hide('modalFilter')">Pesquisar</b-button>
                <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalFilter')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalAnswerDelete" ref="modalAnswerDelete" title="Remover Resposta Rápida" hide-footer header-class="py-0" v-if="selectedAnswer">
            <div class="text-secondary">Tem certeza de que deseja remover essa Resposta Rápida?</div>
            <div class="mt-3">
                <b-button class="modal-btn modal-btnsearch" @click="deleteAnswer">Remover</b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalAnswerDelete')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import api from '../services/apiService.js'

export default {
    props: [
        'user', 'i18n'
    ],
    components: {
        VEmojiPicker
    },
    data() {
        return {
            msgCharLimit: 65536,
            shortcutCharLimit: 25,
            answers: [],
            filters: {},
            departments: [],
            objAux: {},
            selectedAnswer: null,
            showDialog: false,
            loadingAnswers: false,
        }
    },
    mounted: function () {
        this.onCloseModal()
        this.getDepartments()
        this.getAnswers()
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.objAux = {}
                this.filters = {}
            })
        },
        selectAnswer(item) {
            this.selectedAnswer = item
            this.objAux = Object.assign({}, item)
        },
        async getAnswers() {
            this.loadingAnswers = true
            const resp = await api.getQuickAnswers(this.user.channelId || this.user.roleId, this.filters)
            // console.log('answers resp',resp)
            this.loadingAnswers = false
            if(resp.statusCode != 200)  {
                return this.answers = []
            }
            this.answers = resp.quickAnswers
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            // console.log('dep resp',resp)
            if(resp.statusCode != 200)  {
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        depName(_id) { // returns the name of the given _id department
            const depIdToName = new Map(this.departments.map(el => [el._id, el.name]))
            return depIdToName.get(_id) || (_id === 'all' ? 'Todos' : '')
        },
        toggleDialogEmoji() {
            this.showDialog = !this.showDialog;
        },
        selectEmoji(emoji) {
            if(!this.objAux.content)
                this.objAux.content = ""
            this.objAux.content += emoji.data
            this.toggleDialogEmoji()
        },
        async saveAnswer() {
            if(!this.objAux.name) {
                return this.$emit('msg', {
                    text: 'Campo "Título" vazio!',
                    success: false
                })
            }

            if(!this.objAux.departments?.length) {
                return this.$emit('msg', {
                    text: `Campo "Departamentos" vazio!`,
                    success: false
                })                
            }

            if(this.objAux.content.length > this.msgCharLimit) {
                return this.$emit('msg', {
                    text: `Campo "Conteúdo" tem o limite de ${this.msgCharLimit} caracteres!`,
                    success: false
                })
            }

            let resp

            if(this.objAux._id) {
                resp = await api.updateQuickAnswer(this.objAux._id,this.objAux)
            } else {
                resp = await api.createQuickAnswer(this.user.channelId || this.user.roleId,this.objAux)
            }
            // console.log('resp',resp)
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                this.$emit('msg', {
                    text:'Resposta Rápida salva com sucesso!',
                    success: true,
                })
                this.$refs['modalAnswerSave'].hide()
                this.getAnswers()
            } else if (resp.statusCode == 422) {
                this.$emit('msg', {
                    text: 'Campo "Título" ou "Departamentos" vazio!',
                    success: false
                })
            } else if (resp.statusCode == 406) {
                this.$emit('msg', {
                    text: 'Já existe uma Resposta Rápida com este Título!',
                    success: false
                })
            } else {
                this.$emit('msg', {
                    text: 'Ocorreu um erro! Revise os dados e tente novamente.',
                    success: false
                })
            }
        },
        async deleteAnswer() {
            const resp = await api.deleteQuickAnswer(this.selectedAnswer._id)
            // console.log('delete resp',resp)
            if(!resp || resp.statusCode != 200) {
                this.$emit('msg', {
                    text:'Ocorreu um erro!',
                    success: false,
                })
                return this.getTags()
            }
            this.$refs['modalAnswerDelete'].hide()

            this.getAnswers()

            this.$emit('msg', {
                text:'Etiqueta removida com sucesso!',
                success: true,
            })
        },
        tagValidator(tag) {
            // Individual tag validator function
            return tag.length <= 25
        }
    }
}
</script>

<style>
    .emoji-picker{
        position: absolute;
        width: 250px;
    }
    .emoji-picker #Categories {
        order: 5;
    }
    .emoji-picker #InputSearch {
        order: 4;
    }
    .emoji-picker .border{
        border: none !important;
    }
    .emoji-picker .emoji{
        display: flex !important;
        justify-content: center !important;
    }
    .emoji-picker .container-emoji{
        overflow: auto !important;
    }
</style>

<style scoped>
    .answersoptionsbtn{
        margin: 4px;
        padding: 6px;
        border: none;
        border-radius: 2px;
    }
    .answersoptionsbtn:last-child{
        margin-right: 0px;
    }
    .answersoptionsbtn:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .toggle-emoji-icon{
        cursor: pointer;
        font-size: 2em;
    }
    .toggle-emoji-icon:hover{
        color: #555;
    }
    .emoji-picker{
        bottom: 40px;
        right: 0px;
    }
</style>